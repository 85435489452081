import { sanitizeHTML } from "@/lib/utils";

interface FournColumnsSectionData {
  content_collection: {
    id: number;
    site_content_collections_id: {
      collection: {
        item: {
          title?: string;
          text?: string;
        };
      }[];
    };
  }[];
}

interface FournColumnsSectionProps {
  data: FournColumnsSectionData;
}

export const fields = [
  "content_collection.id",
  "content_collection.site_content_collections_id.collection.item:site_content.title",
  "content_collection.site_content_collections_id.collection.item:site_content.text",
] as const;

export default function FournColumnsSection({
  data,
}: FournColumnsSectionProps) {
  return (
    <section id="KeyFeaturesSection" className="bg-[#f3f1f1] py-[70px]">
      <div className="container sm:px-6 lg:px-8 w-[90%]">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-0 max-w-[1410px] mx-auto text-sm">
          {data?.content_collection?.map((column, index) => (
            <div
              key={column.id}
              className={`mb-${index === 3 ? "0" : "8 lg:mb-0"} px-2.5`}
            >
              <div className="relative pl-[60px]">
                {column.site_content_collections_id.collection[0].item
                  .title && (
                  <h3 className="font-freightBig text-xl sm:text-2xl text-black mb-2.5 before:content-[''] before:absolute before:left-0 before:top-[1.25rem] before:w-10 before:h-[1px] before:bg-[#d9d9d9]">
                    {
                      column.site_content_collections_id.collection[0].item
                        .title
                    }
                  </h3>
                )}
                {column.site_content_collections_id.collection[1].item.text && (
                  <div
                    className="font-aileron text-black leading-normal"
                    dangerouslySetInnerHTML={sanitizeHTML(
                      column.site_content_collections_id.collection[1].item.text
                    )}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

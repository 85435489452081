import Link from '@/components/ui/Link'
import Image from 'next/image'
import { sanitizeHTML } from '@/lib/utils'
import { getReadableImageUrl } from '@/lib/directus'
import { useEffect, useState } from 'react'

interface FooterData {
	bottom_line: {
		title: string
	}
	bottom_line_menu: {
		menu_items: {
			menu_items_id: {
				label: string
				href: string
			}
		}[]
	}
	address_text: {
		text: string
	}
	address_title: {
		title: string
	}
	contact_title: {
		title: string
	}
	contact_phone_title: {
		title: string
	}
	contact_email_title: {
		title: string
	}
	contact_email: {
		title: string
	}
	contact_phone: {
		title: string
	}
	map_link: {
		href: string
		label: string
	}
	our_services_title: {
		title: string
	}
	our_services_menu: {
		menu_items: {
			menu_items_id: {
				label: string
				href: string
			}
		}[]
	}
	google_rating_section: {
		text: string
	}
	google_rating_title: {
		title: string
	}
	social_links: {
		external_links_id: {
			url: string
			label: string
			target: string
			icon: {
				id: string
				filename_disk: string
			}
		}
	}[]
}

export interface FooterProps {
	data: FooterData
}

export const fields = [
	'bottom_line.*',
	'bottom_line_menu.menu_items.menu_items_id.label',
	'bottom_line_menu.menu_items.menu_items_id.href',

	'address_text.text',
	'address_title.title',

	'contact_title.title',
	'contact_phone_title.title',
	'contact_email_title.title',
	'contact_email.title',
	'contact_phone.title',

	'map_link.href',
	'map_link.label',

	'google_rating_title.title',
	'google_rating_section.text',

	'our_services_title.title',
	'our_services_menu.menu_items.menu_items_id.label',
	'our_services_menu.menu_items.menu_items_id.href',

	'social_links.external_links_id.url',
	'social_links.external_links_id.target',
	'social_links.external_links_id.icon.id',
	'social_links.external_links_id.icon.filename_disk',
] as const

export default function Footer({ data }: FooterProps) {
	const [showScrollTop, setShowScrollTop] = useState(false)

	useEffect(() => {
		const handleScroll = () => {
			// Show button when user scrolls down 300px from the top
			if (window.scrollY > 300) {
				setShowScrollTop(true)
			} else {
				setShowScrollTop(false)
			}
		}

		// Add scroll event listener
		window.addEventListener('scroll', handleScroll)

		// Clean up the event listener
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
		<>
			<footer className="relative bg-black text-white [&_a:not([href*='book-a-consultation'])]:underline leading-normal border-t border-white/10 md:pb-0 pb-[60px]">
				<div className='py-[100px] lg:py-[130px] px-5 sm:px-10'>
					<div className='container relative z-10 max-w-[1272px]'>
						<div className='grid grid-cols-1 md:grid-cols-3 gap-8 lg:gap-12'>
							<div>
								<h2 className='text-sm font-bold mb-2'>
									{data.contact_title.title}
								</h2>
								<div className='text-sm'>
									<p>
										{data.contact_phone_title.title}{' '}
										<Link
											href={`tel:${data.contact_phone.title}`}
											className='text-white hover:underline'
										>
											{data.contact_phone.title}
										</Link>
									</p>
									<p>
										{data.contact_email_title.title}{' '}
										<Link
											href={`mailto:${data.contact_email.title}`}
											className='text-white hover:underline'
										>
											{data.contact_email.title}
										</Link>
									</p>
									<br />
									<h2 className='text-sm font-bold mt-2'>
										{data.address_title.title}
									</h2>
									<div
										dangerouslySetInnerHTML={sanitizeHTML(
											data.address_text.text
										)}
									/>
									<br />
									<Link
										href={data.map_link.href}
										className='text-white hover:underline'
									>
										{data.map_link.label}
									</Link>
								</div>
								<div className='flex gap-4 mt-8'>
									{(data?.social_links || []).map(button => (
										<Link
											key={button.external_links_id.url}
											href={button.external_links_id.url}
											className='text-blac'
											aria-label={button.external_links_id.label}
											target={button.external_links_id.target}
										>
											<Image
												src={getReadableImageUrl(
													button.external_links_id.icon.id,
													button.external_links_id.icon.filename_disk
												)}
												alt={button.external_links_id.label || ''}
												width={20}
												height={20}
												className='w-5 h-5 invert'
												quality={95}
												loading={'lazy'}
											/>
										</Link>
									))}
								</div>
							</div>
							<div>
								<h2 className='text-sm font-bold mb-2'>
									{data.our_services_title.title}
								</h2>
								<ul className='space-y-3 text-sm'>
									{data.our_services_menu.menu_items.map((item, index) => (
										<li key={`service-${item.menu_items_id.href}-${index}`}>
											<Link
												href={item.menu_items_id.href}
												className='text-white hover:underline !no-underline'
											>
												{item.menu_items_id.label}
											</Link>
										</li>
									))}
								</ul>
							</div>
							<div>
								<h2 className='text-sm font-bold mb-2'>
									{data.google_rating_title.title}
								</h2>
								{data.google_rating_section.text && (
									<div
										dangerouslySetInnerHTML={sanitizeHTML(
											data.google_rating_section.text
										)}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className='bg-black py-4 relative z-10 border-t border-white/10 leading-loose px-8'>
					<div className='text-center text-xs text-white font-bold'>
						{data.bottom_line.title && <span>{data.bottom_line.title}</span>}
						{data.bottom_line_menu.menu_items.map((item, index) => (
							<span key={`bottom-${item.menu_items_id.href}-${index}`}>
								<span className='mx-1'>|</span>
								<Link
									href={item.menu_items_id.href}
									className='!no-underline hover:underline'
								>
									{item.menu_items_id.label}
								</Link>
							</span>
						))}
					</div>
				</div>
			</footer>

			{/* Mobile sticky navbar */}
			<div className='md:hidden fixed bottom-0 left-0 right-0 bg-white flex justify-between items-center p-2 shadow-lg z-50'>
				<Link
					href={`tel:${data.contact_phone.title}`}
					className='w-[48%] py-2 text-center text-white bg-black border-2 border-black rounded-md font-bold !no-underline hover:bg-white hover:text-black transition-colors duration-300 text-sm'
				>
					Call Us
				</Link>
				<Link
					href='/contact-us/'
					className='w-[48%] py-2 text-center text-white bg-black border-2 border-black rounded-md font-bold !no-underline hover:bg-white hover:text-black transition-colors duration-300 text-sm'
				>
					Book Consultation
				</Link>
			</div>

			{/* Jump to top button */}
			<button
				onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
				className={`fixed bottom-20 right-6 md:bottom-8 md:right-8 bg-black text-white w-10 h-10 rounded-full flex items-center justify-center shadow-lg hover:bg-gray-800 transition-all duration-300 z-50 ${
					showScrollTop 
						? 'opacity-100 transform scale-100' 
						: 'opacity-0 transform scale-75 pointer-events-none'
				}`}
				aria-label="Jump to top"
			>
				<svg
					width="16"
					height="16"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M12 5L5 12M12 5L19 12M12 5V19"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>
		</>
	)
}
import Link from "@/components/ui/Link";
import Button from "../ui/button";
import ExpandingPanel from "./ExpandingPanel";
import { sanitizeHTML } from "@/lib/utils";
import { useState } from "react";

interface FrequentlyQuestionsData {
  heading?: {
    text?: string;
    title?: string;
  };
  content_collection?: {
    id: string;
    site_content_collections_id: {
      collection: {
        item: {
          title: string;
          text: string;
        };
      }[];
    };
  }[];
  buttons?: {
    menu_items_id: {
      href: string;
      label: string;
    };
  }[];
  button?: {
    label?: string;
    href?: string;
  };
  subheading?: {
    text?: string;
    title?: string;
  };
  video?: {
    embed_url: string;
    embed_title: string;
  };
  text?: {
    text?: string;
  };
}

export const fields = [
  "heading.text",
  "heading.title",

  "subheading.text",
  "subheading.title",

  "content_collection.id",
  "content_collection.site_content_collections_id.collection.item:site_content.title",
  "content_collection.site_content_collections_id.collection.item:site_content.text",

  "buttons.menu_items_id.href",
  "buttons.menu_items_id.label",

  "text.text",

  "button.label",
  "button.href",

  "video.embed_url",
  "video.embed_title",
] as const;

export default function FrequentlyQuestions({
  data,
}: {
  data?: FrequentlyQuestionsData;
}) {
  const [openIndex, setOpenIndex] = useState<number>(0);

  return (
    <section id="FrequentlyQuestions" className="py-[54px] px-4">
      {data?.subheading && (
        <h4
          className="font-freightBig text-sm text-center mt-[10px] capitalize"
          dangerouslySetInnerHTML={sanitizeHTML(
            data.subheading.title || data.subheading.text || ""
          )}
        />
      )}
      <div className="max-w-[1085px] mx-auto">
        <h2
          className="font-freightBig text-4xl leading-tight md:leading-normal md:text-5xl text-secondary-dark mb-8 text-left"
          dangerouslySetInnerHTML={sanitizeHTML(
            data?.heading?.title ||
              data?.heading?.text ||
              "Frequently Asked Questions"
          )}
        />
      </div>
      {data?.text?.text && (
        <div className="mt-[20px] text-center">
          <p dangerouslySetInnerHTML={sanitizeHTML(data.text.text)} />
        </div>
      )}
      {data?.video && (
        <div className="mt-[20px] flex justify-center">
          <iframe
            width="560"
            height="315"
            src={data.video.embed_url}
            title={data.video.embed_title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
      )}
      <div className="max-w-[1085px] mx-auto">
        <div className="mt-[38px] space-y-0">
          {data?.content_collection?.map((item, index) => {
            const collection =
              item.site_content_collections_id?.collection || [];
            const title =
              collection.find((c) => c.item?.title)?.item?.title || "";
            const text = collection.find((c) => c.item?.text)?.item?.text || "";

            const isOpen = openIndex === index;

            return (
              <div
                key={item.id}
                className="border-b border-black pb-5"
              >
                <button
                  onClick={() => setOpenIndex(isOpen ? null : index)}
                  className="w-full flex justify-between items-center gap-[16.99px] py-[23px]"
                >
                  <div className="flex items-center gap-2">
                    <h3 className="text-black text-2xl md:text-4xl font-light font-freightBig leading-snug text-left">
                      {title}
                    </h3>
                    {!isOpen && (
                      <svg
                        className="w-[32px] h-4 md:w-[49px] transition-transform" 
                        viewBox="0 0 49 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M48.695 8.40779C49.0855 8.01727 49.0855 7.3841 48.695 6.99358L42.3311 0.629616C41.9405 0.239091 41.3074 0.239091 40.9168 0.629616C40.5263 1.02014 40.5263 1.65331 40.9168 2.04383L46.5737 7.70068L40.9168 13.3575C40.5263 13.7481 40.5263 14.3812 40.9168 14.7718C41.3074 15.1623 41.9405 15.1623 42.3311 14.7718L48.695 8.40779ZM0.987915 8.70068H47.9879V6.70068H0.987915V8.70068Z"
                          fill="black"
                        />
                      </svg>
                    )}
                  </div>
                </button>
                {isOpen && (
                  <div
                    className="text-black text-lg font-light font-aileron leading-7 tracking-tight mt-[23px]"
                    dangerouslySetInnerHTML={sanitizeHTML(text)}
                  />
                )}
              </div>
            );
          })}
        </div>
        {data?.button && (
          <div className="flex justify-center pt-3.5 pb-[27px]">
            <Button
              asChild
              variant="outline"
              className="px-9 pt-4 pb-[17px] rounded-[100px] border-[#b2b2b2]"
            >
              <Link href={data.button.href || "#"}>
                <span className="text-center text-[#332f2c] text-xs font-bold font-jakarta uppercase leading-tight tracking-wider">
                  {data.button.label}
                </span>
              </Link>
            </Button>
          </div>
        )}
        <div className="flex justify-center mt-[50px]">
          <Button
            asChild
            variant="outline"
            className="inline-flex items-center justify-center px-[35px] py-[15px] text-[12px] font-plusJakarta font-bold uppercase tracking-[0.1em] text-[#332f2c] border border-[#b2b2b2] rounded-full transition-all duration-300 hover:bg-[#645e5e] hover:text-white hover:border-black"
          >
            <Link href={data?.buttons?.[0]?.menu_items_id?.href || "#"}>
              READ MORE
            </Link>
          </Button>
        </div>
      </div>
    </section>
  );
}
